import { PageProps } from 'gatsby';
import React, { useEffect } from 'react';

interface PageNotFoundProps {}

const getRedirectPath = (path: string): string => {
  const URL_PARTS = path.split('/');

  const pageNotFoundUrls: { [key: string]: string } = {
    'en-gb': '/en-gb/page-not-found/',
    'en-us': '/en-us/page-not-found/',
    'ja-jp': '/ja-jp/404/',
    'nl-nl': '/nl-nl/404',
    'de-de': '/de-de/404/',
    'fr-fr': '/fr-fr/404/',
  };

  const languageSlug = URL_PARTS.length ? URL_PARTS[1] : '';

  return pageNotFoundUrls[languageSlug] || pageNotFoundUrls['en-gb'];
};

const PageNotFound: React.FC<PageProps<PageNotFoundProps>> = ({ location }) => {
  const redirectPath = getRedirectPath(location.pathname);

  useEffect(() => {
    window.location.replace(redirectPath);
  }, [redirectPath]);

  return null;
};

PageNotFound.defaultProps = {};

PageNotFound.propTypes = {};

export default PageNotFound;
